<template>
    <v-row>
        <app-loader :value="$apollo.loading"/>
        <v-col cols="12" v-if="accountData && accountData.messages.length > 0">
            <v-card flat>
                <nice-header>Messages</nice-header>
                <v-list subheader>
                    <v-subheader class="title">Finish setting up your account</v-subheader>

                    <template
                            v-for="(item, i) in accountData.messages"

                    >
                        <v-list-item :key="i">
                            <v-list-item-avatar>
                                <v-avatar class="primary">
                                    <span class="white--text headline">{{i+1}}</span>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.message"></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-btn icon link :to="item.url">
                                    <v-icon>mdi-launch</v-icon>
                                </v-btn>
                            </v-list-item-icon>
                        </v-list-item>
                        <v-divider inset :key="`divider-${i}`"></v-divider>
                    </template>
                </v-list>
            </v-card>
        </v-col>

        <v-col cols="12" md="6" v-if="accountData">
            <v-card flat height="352px">
                <nice-header>Upcoming bookings</nice-header>
                <v-simple-table>
                    <thead>
                    <tr>
                        <th class="text-left">Customer</th>
                        <th class="text-left">Collection time</th>
                        <th class="text-left">View booking</th>
                    </tr>
                    </thead>
                    <tbody v-if="accountData.next5Bookings.length > 0">
                    <tr v-for="order in accountData.next5Bookings" :key="order.id">
                        <td>{{order.customer.name}}</td>
                        <td>{{order.collectionDate | niceTime }}</td>
                        <td>
                            <v-btn icon link :to="`/orders/${order.id}`" small color="primary">
                                <v-icon>mdi-launch</v-icon>
                            </v-btn>
                        </td>
                    </tr>

                    </tbody>
                    <tbody v-else>
                    <tr>
                      <td colspan="3">You currently have no upcoming bookings</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" v-if="accountData">
            <v-row>
                <v-col cols="12" sm="6" class="pt-0">
                    <v-card flat height="170px">
                        <nice-header>
                            Orders placed
                        </nice-header>
                        <v-card-title class="justify-end align-right display-3 mx-6 primary--text">
                            {{ accountData.orderCount }}
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                    <v-card flat height="170px">
                        <nice-header>
                            Inventory size
                        </nice-header>
                        <v-card-title class="justify-end align-right display-3 mx-6 primary--text">
                            {{ accountData.assetCount }}
                        </v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                    <v-card flat height="170px">
                        <nice-header>
                            Customer count
                        </nice-header>
                        <v-card-title class="justify-end align-right display-3 mx-6 primary--text">
                            {{ accountData.customerCount }}
                        </v-card-title>
                    </v-card>
                </v-col>

            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import { accountData } from '@/graphql'
    import moment from 'moment'

    const formatMoney = function (number) {
        var decPlaces = 2;
        const decSep = '.';
        const thouSep = ',';

        var sign = number < 0 ? "-" : "";
        var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
        var j = (j = i.length) > 3 ? j % 3 : 0;

        return sign +
            (j ? i.substr(0, j) + thouSep : "") +
            i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
            (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
    };


    export default {
        name: 'Home',
        apollo: {accountData},
        filters: {
            niceTime(value) {
                return moment(value).calendar()
            },
            asMoney: v => formatMoney(v)

        }
    }
</script>
